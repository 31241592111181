import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, LinkButton, OvalLoading, getModuleLink } from "../../../components";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { assessmentActions, getAssessment } from "../../../store";
const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
const Attributes = [
  { label: "Title", attribute: "title", width: 12 },
  { label: "Model", attribute: "model_title", width: 12 },
  { label: "Description", attribute: "description", width: 12 },
  { label: "Audit Type", attribute: "audit_type", width: 6 },
  { label: "Status", attribute: "audit_status", width: 6 },
  { label: "Assessor", attribute: "assessor_name", width: 6 },
  { label: "Auditee", attribute: "auditee_name", width: 6 },
  { label: "Start Date", attribute: "start_date", width: 6 },
  { label: "End Date", attribute: "end_date", width: 6 },
  { label: "Actual Start Date", attribute: "actual_start_date", width: 6 },
  { label: "Actual End Date", attribute: "actual_end_date", width: 6 },
]
export default (props) => {
  const { asmtId } = props;
  const dispatch = useDispatch();
  const asmt = useSelector(getAssessment.bind(null, asmtId))
  useEffect(() => {
    dispatch(assessmentActions.fetchAssessment({ asmtId }))
  }, [])
  const handleClose = () => {
    props.onClose()
  }
  const handleEdit = () => {
    const link = `https://auditor.${DOMAIN}/A/${asmt.guid || asmt.id}/edit`;
    window.open(link, '_blank')
  }
  return (
    <Dialog open onClose={handleClose} className='dialog' classes={{ paper: `info-dialog asmt-dialog ${props.className || ''}` }}>
      <DialogTitle classes={{ root: 'title' }}>
        <div className='h4 exo2 c-primary'>Audit Details</div>
      </DialogTitle>
      <DialogContent>
        {
          Boolean(asmt) ?
            <Grid container columnSpacing={2}>
              {
                Attributes.map((_, i) => {
                  return (
                    <Grid key={i} item lg={_.width} md={_.width} xs={6} sm={6}>
                      <div className='row h11 w-100 v-start'>
                        <div className=' key exo2 no-wrap'>{_.label}:</div>
                        <div className=''>
                          <p className={_.attribute === 'description'? 'justified-text':'no-wrap' }>{asmt[_.attribute]}</p>
                        </div>
                      </div>
                    </Grid>
                  )
                })
              }
            </Grid>
            :
            <div className='col w-100 load'>
              <OvalLoading isSmall />
            </div>
        }
      </DialogContent>
      <DialogActions classes={{ root: 'row actions v-end' }}>
        {Boolean(asmt) && <PrimaryButton text="Edit" onClick={handleEdit} />}
        <LinkButton label="Close" onClick={handleClose} />
      </DialogActions>
    </Dialog>
  )
}

