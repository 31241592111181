import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import { Input, AutoCompleteSelect, PrimaryButton, Checkbox, RightArrowIcon, Button } from "../../components";
import { getDepartmentsList, getRolesList, getRoles, getRolesIdsList, getCountryCodes, getDesignationList } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import FormFields from "./formFields.json";
// import "../style.scss";

export const UserForm = (props) => {
  const dispatch = useDispatch()
  const RolesConstant = getRoles()
  const { user, btnSubmit, btnReset, onSubmit, nonEditable, currentUser, roleLevelLimit, readOnly, hiddenFields, identicalFields } = props;
  const departments = useSelector(getDepartmentsList);
  const designations = useSelector(getDesignationList);
  const roles = useSelector(getRolesList.bind(null, currentUser ? currentUser.role.name : (roleLevelLimit || RolesConstant.Assessor)))
  const RolesIds = useSelector(getRolesIdsList)
  const countryCodes = useSelector(getCountryCodes)
  const isContinuerResponder = props.isContinuerResponder
  const isInvestigatorResponder = props.isInvestigatorResponder
  const isArcResponder = props.isArcResponder
  const [state, setState] = useState({ formData: null, error: {} });
  const showCancel = props.showCancel !== undefined ? props.showCancel : true
  useEffect(() => {
    setState((_) => ({ ..._, formData: getFormData(user) }))
  }, []);
  const onAddSubmit = (e) => {
    e.preventDefault();
    let valid = true, error = {}, formData = {};
    if (!readOnly) {
      FormFields.user.forEach((row) => {
        row.map(({ type, name, required }) => {
          if (!shouldHidden({ name })) {
            let value = state.formData[name];
            if (required && !value) {
              valid = false;
              error[name] = 'required'
            } else {
              if (type === 'select') {
                formData[name] = value.id
              } else {
                formData[name] = value
              }
            }
          }
        })
      })
      if (!valid) {
        return setState((_) => ({ ..._, error: error }))
      }
      //To Be Removed
      // formData.role_id = RolesIds.find((_) => _.label === formData.role_id).id
      formData.dept_lead = Boolean(formData.dept_lead);
    }
    onSubmit && onSubmit(formData);
  }
  const onValueChange = (e) => {
    const { name, value, type, checked } = e.target, formData = { ...state.formData }, error = { ...state.error };
    if (Array.isArray(nonEditable) && nonEditable.indexOf(name) > -1) {
      return
    }
    formData[name] = type === 'checkbox' ? checked : value;
    if (name === 'role' && value && value.idStr !== RolesConstant.Auditee) {
      formData.dept_lead = false
    }
    if (identicalFields && identicalFields[name]) {
      formData[identicalFields[name]] = value
    }
    error[name] = ''
    setState((_) => {
      return { ..._, formData: { ...formData }, error: error }
    })
  }
  const getFormData = (user) => {
    const data = {};
    FormFields.user.map((row) => {
      row.map((field) => {
        let value = user ? user[field.name] : '';
        if (field.type === 'select') {
          value = value || field.default || '';
          let options = getOptions(field.name);
          let found = options.find((_) => _.id === value)
          if (found) {
            value = found
          } else {
            value = field.allowTypedAsOption ? { id: value, label: value } : value
          }
          data[field.name] = value;
          // console.log(`options ${field.name}`, options, value, field.name, data[field.name]);
        } else {
          data[field.name] = value || '';
        }
      })
    });
    return data;
  }
  const getOptions = (field) => {
    if (field === 'role_id') {
      // console.log(roles);
      return roles;
    } else if (field === 'department_name') {
      return departments;
    } else if (field === 'country_code') {
      return countryCodes;
    } else if (field === 'designation') {
      return designations;
    }
    return []
  }
  const shouldHidden = (field) => {
    let fields = Array.isArray(field) ? field : [field];
    if (!Array.isArray(hiddenFields) || (Array.isArray(hiddenFields) && hiddenFields.length === 0)) {
      return false
    }
    const hiddens = fields.filter((_) => hiddenFields.indexOf(_.name) > -1)
    return hiddens.length === fields.length
  }

  return (
    state.formData === null ? null
      :
      <>
        <form className='form col oy-auto f-rest' onSubmit={onAddSubmit} id="org-form" noValidate={true}>
          {
            FormFields.user.map((row, index) => {
              return (
                shouldHidden(row) ? null :
                  <Grid container columnSpacing={4} key={index} direction="row" justifyContent="space-between" alignItems="flex-end">
                    {
                      row.map(field => {
                        const { isfacility, ...rest } = field;
                        let width = field.width;
                        const _props = { editable: !readOnly, error: state.error[field.name], disabled: props.disabled || rest.disabled, labelClassName: 'h12', className: "h8", onChange: onValueChange, value: state.formData[field.name], ...rest };
                        if (Array.isArray(nonEditable) && nonEditable.indexOf(rest.name) > -1) {
                          _props.editable = false
                        }
                        if (field.name === 'dept_lead') {
                          _props.disabled = state.formData && state.formData.role_id ? Boolean(state.formData.role_id.idStr !== RolesConstant.Auditee) : true
                        }
                        if (field.name === 'department_name' && shouldHidden({ name: 'dept_lead' })) {
                          width = 6;
                        }

                        return (
                          shouldHidden(field) ? null :
                            <Grid item xs={12} sm={12} md={width} lg={width} key={field.name}>
                              {
                                field.type === 'select' ?
                                  <AutoCompleteSelect
                                    options={getOptions(field.name)}
                                    {..._props}
                                  />
                                  : field.type === 'checkbox' ?
                                    <Checkbox {..._props} labelClassName={_props.editable ? 'h7' : ''} />
                                    :
                                    <Input {..._props} type={field.type} />
                              }
                            </Grid>
                        )
                      })
                    }
                  </Grid>
              )
            })
          }
          <p className='f14'>Tag(s)</p>
          <div className='d-flex'>
            <p className='f14' style={{ paddingTop: '5px' }} >Dept Lead: <span className='f20'>{(readOnly) && (user.dept_lead ? 'Yes' : 'No')}</span> </p>
            {(!readOnly) && <div><Checkbox name='dept_lead' disabled={true} /></div>}

          </div>
          <div style={{ marginBottom: '20px' }} className='row'>
            <div className='f14' style={{ marginRight: '7px' }}>Responder: </div>
            <div className='f20'>{!(isContinuerResponder || isInvestigatorResponder || isArcResponder) && 'No'}</div>
            <div className='f20' style={{ marginRight: '10px' }}>{isContinuerResponder ? 'Continuer' : ''}</div>
            <div className='f20' style={{ marginRight: '10px' }}>{isInvestigatorResponder ? 'Investigator' : ''}</div>
            <div className='f20'>{isArcResponder ? 'Arc' : ''}</div>
          </div>
          <div className='row v-ctr h-btn form-actions'>
            <div className='row'>
              <PrimaryButton disabled={props.disabled} type='submit' endIcon={<RightArrowIcon />} text={btnSubmit || 'Create'} />
              {
                showCancel &&
                <Button disabled={props.disabled} text={btnReset || 'Cancel'} className='h8 med reset-btn' onClick={props.onCancel} />
              }
            </div>
          </div>
        </form>
      </>
  )
}
export const UserFormFields = FormFields;