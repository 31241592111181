import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Screen, HelpSection, FileInput, PrimaryButton, BulkUserUploadTemplate } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserOrg, userActions, isUserCreated, getOrgDetailsById } from "../../store";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const currentOrg = useSelector(getCurrentUserOrg)
  const org = useSelector(orgId ? getOrgDetailsById.bind(null, orgId) : getCurrentUserOrg);
  const isCreated = useSelector(isUserCreated);
  const [state, setState] = React.useState({ selectedFile: null })
  useEffect(() => {
    if (!org) {
      navigate('/page-not-found')
    }
  }, [org])
  useEffect(() => {
    if (isCreated) {
      
    }
  }, [isCreated])
  const onSubmit = (e) => {
    e.preventDefault()
    const file = state.selectedFile
    if (file) {
      const payload = new FormData();
      payload.append('org_id', org.id);
      payload.append('file', file);
      dispatch(userActions.bulkUploadUsers(payload))
      setState((_) => ({ ..._, selectedFile: null }))
    }
  }
  const handleSelectFile = (e) => {
    setState((_) => ({ ..._, selectedFile: e.target.files[0] }))
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <Screen className='user-add col f-rest o-hide'>
        <Grid container spacing={0} classes={{ root: 'o-hide' }} >
          <Grid item xs={12} sm={12} md={9} lg={8} classes={{ root: 'col h-100  o-hide' }} direction="column">
            <h1 className='exo2 h5 med page-head'>Upload Users {org.id !== currentOrg.id ? `for ${org.brandname}` : ''}</h1>
            <form className='form col oy-auto' onSubmit={onSubmit} id="upload-user-form" noValidate="true">
              <a href={BulkUserUploadTemplate} download="Bulk Upload Template.xlsx" target="_blank" className='h12 exo2 template'>Download Bulk Upload Template</a>
              <FileInput
                accept='.csv'
                placeholder='Select File'
                labelClassName='h12'
                className="h10"
                value={state.selectedFile}
                onChange={handleSelectFile} />
              <div className='row h-end '>
                <PrimaryButton type='submit' className='submit-btn' text={'Upload'} />
              </div>
            </form>
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={4} />
        </Grid>
      </Screen>
    </div>
  )
}
