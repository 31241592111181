import React, { useEffect, useState, useRef } from 'react';
import { Dialog, Slide, Snackbar, Alert } from '@mui/material';
import { Button, LinkButton } from "../Button";
import "./style.scss";
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const DialogButton = (props) => {
  const { type, ...rest } = props;
  return (
    type === 'link' ?
      <LinkButton {...rest} />
      :
      <Button {...props} />
  )
}

export const ConfirmationBox = (props) => {
  const { closeOnEnter, subtitle } = props
  const [state, setState] = useState({ password: '' })
  let subtitles = Array.isArray(subtitle) ? subtitle : (subtitle ? [subtitle] : []);
  const btnCancel = props.btnCancel ? { ...props.btnCancel } : null;
  const btnOk = props.btnOK || {}
  const withPassword = props.withPassword || false;
  const cancelRef = useRef(null)
  useEffect(() => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      if (closeOnEnter && cancelRef.current) {
        cancelRef.current.focus()
      }
    }, 500)
  }, [])
  const handlePassword = (e) => {
    setState((_) => ({ ..._, password: e.target.value }))
  }
  const handlePasswordSubmit = (e) => {
    e.preventDefault()
    if (e.code === 'Enter' && btnCancel && btnCancel.onClick) {
      btnCancel.onClick()
    } else {
      if (withPassword && state.password.length === 0) {
        return
      }
      btnOk.onClick(e, state.password)
    }
  }
  const handleEscape = (e) => {
    e.stopPropagation();
    if (e.code === 'Escape' && btnCancel && btnCancel.onClick) {
      btnCancel.onClick()
    }
  }
  return (
    <Dialog open className='dialog ' onClose={handleEscape} classes={{ paper: `confirm-dialog ${props.className || ''}` }}>
      <div className={`${props.contentClassName} col content`}>
        <strong className={`h5 exo2 heading ${props.titleClassName || ''}`}>{props.title}</strong>
        <div className='sub-title-cnt'>
          {
            subtitles.map((subtitle, index) => {
              const isObject = (typeof subtitle === "object")
              return (
                <React.Fragment key={index}>
                  {
                    isObject ?
                      <p className={`h9 reg subheading ${props.subtitleClassName || ''}`} dangerouslySetInnerHTML={{ __html: subtitle.html }}></p>
                      :
                      // <p className={`h9 reg subheading ${props.subtitleClassName || ''}`}>{subtitle}</p>
                      <p className={`h9 reg subheading ${props.subtitleClassName}`} dangerouslySetInnerHTML={{ __html: subtitle.replace(/\n/g, '<br>') }} />
                  }
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
      {
        withPassword &&
        <form className='row w-100 v-end' onSubmit={handlePasswordSubmit}>
          <div className='col f-rest sec-pass'>
            <strong className='h12 reg'>To confirm this, please enter the Password</strong>
            <input type="password" className='h8 input' placeholder='Password' value={state.password} onChange={handlePassword} />
          </div>
          <DialogButton
            type='submit'
            color={btnOk.color || '#1177CC'}
            className={`h7 btn-ok ${btnOk.className || ''}`}
            text={btnOk.text || 'Okay'}
            onClick={handlePasswordSubmit}
            variant={btnOk.variant}
            endIcon={btnOk.endIcon}
            startIcon={btnOk.startIcon}
            disableElevation={btnOk.disableElevation || false} />
        </form>
      }
      <div className={`${btnOk.className ? btnOk.className : (props.row ? 'row action-row' : 'col flex-ctr v-ctr h-ctr action')}`}>
        {
          !withPassword &&
          <DialogButton
            type={btnOk.type}
            color={btnOk.color || '#1177CC'}
            className={`h7 btn-ok ${btnOk.className || ''}`}
            text={btnOk.text || 'Okay'}
            onClick={handlePasswordSubmit}
            variant={btnOk.variant}
            endIcon={btnOk.endIcon}
            startIcon={btnOk.startIcon}
            disableElevation={btnOk.disableElevation || false} />
        }
        {
          Boolean(btnCancel) &&
          <DialogButton
            btnRef={cancelRef}
            type={btnCancel.type}
            color={btnCancel.color}
            className={`h7 btn-cancel ${btnCancel.className || ''}`}
            text={btnCancel.text || 'Cancel'}
            onClick={btnCancel.onClick}
            variant={btnCancel.variant}
            disableElevation={btnCancel.disableElevation || false} />
        }
      </div>
    </Dialog>
  )
}
export const ToastAlert = (props) => {
  let title = Array.isArray(props.title) ? props.title : [props.title]
  return (
    <Snackbar
      open
      autoHideDuration={70000}
      onClose={props.onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      TransitionComponent={TransitionDown}>
      <Alert
        onClose={props.onClose}
        classes={{ root: 'logs', message: 'h9' }}
        severity="error" >
        {
          title.map((_, i) => {
            return <><span key={i}>{_}</span>{i < (title.length - 1) && <br />}</>
          })
        }
      </Alert>
    </Snackbar>
  )
}

