import React from 'react';
import MuiButton from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import "./style.scss";

export const LinkButton = (props) => {
  const { type, className, label, text, btnRef, ...rest } = props;
  return (
    <button type={type || 'button'} ref={btnRef} className={`link-btn ${className || ''} ${rest.disabled && 'disabled'}`} {...rest}>
      <span>{label || text}</span>
    </button>
  )
}
export const AddButton = (props) => {
  const { text, type, className, ...rest } = props
  const showIcon = props.showIcon !== undefined ? props.showIcon : true;
  return (
    <MuiButton type={type || 'button'} classes={{ root: 'fam-robo-med' }} className={`add-btn ${className || ''}`} variant="contained" {...rest}>
      <span className='btn-text'>{text}</span>
      {showIcon && <i className='icon-plus' />}
    </MuiButton>
  )
}
export const Button = (props) => {
  const { text, type, color, className, loading, font, ...rest } = props;
  const buttonProps = {
    type: type || 'button',
    classes: { root: `${Boolean(font) ? font : 'h8'} med app-btn ${loading && 'btn-load'} ${className || ''}`, loadingIndicator: 'loading' },
    style: color && !rest.disabled ? { backgroundColor: color } : {},
    variant: "contained",
    loadingPosition: Boolean(rest.endIcon) ? 'end' : undefined,
    startIcon: rest.startIcon || null, 
    ...rest
  }
  return (
    <>
      <LoadingButton
        loading={loading || false}
        variant="outlined" {...buttonProps}>
        <span className='btn-text'>{(loading && rest.textLoading) ? rest.textLoading : text}</span>
      </LoadingButton>
    </>
  )
}
export const IconButton = (props) => {
  return (
    <MuiIconButton {...props}>
      {props.children}
    </MuiIconButton>
  )
}
export const PrimaryButton = (props) => {
  const { className,color, ...rest } = props
  return (
    <Button  color={color? color: '#1177CC'} className={`primary-btn ${className}`} {...rest} />
  )
}
export const DisabledButton = (props) => {
  const { className, ...rest } = props
  return (
    <Button color='#D9D9D9' className={`primary-btn ${className}`} {...rest} />
  )
}
